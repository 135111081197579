import React, { useState, useRef, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { useOnClickOutside } from "../../hooks";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import Burger from "./Burger/Burger";
import Social from "../Social";
import Menu from "./Menu/Menu";

function Header() {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  useOnClickOutside(node, () => setOpen(false));

  // Function to check if the device is a desktop
  const isDesktop = () => window.innerWidth > 768; // Adjust this value as needed

  // State to store whether the device is a desktop or not
  const [isDesktopDevice, setIsDesktopDevice] = useState(isDesktop());

  useEffect(() => {
    // Function to handle resizing of the window
    const handleResize = () => {
      setIsDesktopDevice(isDesktop());
    };

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="header" style={{ zIndex: 1, display: "flex", justifyContent: "space-between", padding: "30px", marginBottom: "10px" }}>
        <Social />
        {/* <Barra /> */}
        <div>
          <div ref={node}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
            {isDesktopDevice && ( // Only render the button if it's a desktop device
              <button style={{ marginRight: "42px", paddingTop: "5px", fontSize: "1.2rem", color: "white", border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={setOpen}> MENU </button>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider >
  );
}

export default Header;
