import React from "react";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import Header from "../../components/Navigation/Header"

import Background from "../../components/Background";
import FirstPage from "../../components/News/FirstPage";
import { logEvent } from "firebase/analytics";
import {
  analytics
} from "../../firebase/firebase";


function Home() {

  logEvent(analytics, 'screen_view', {
    firebase_screen: "home_page",
    firebase_screen_class: "class"
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <div className="App" style={{
        color: "white",
      }}>


        <Helmet>
          <title>Pietro Bonazzi</title>
          <meta name="description" content="Pietro Bonazzi  (born January 30, 1999, in Turin) 
          is an Italian research scientist, engineer and entrepreneur, specialized in machine learning." />
        </Helmet>

        {/* <div style={{ display: "flex", justifyContent: "center", backgroundColor: "yellow", color: "black" }}> Advertisment slot </div> */}

        <Header />
        <FirstPage />
        <Background />


      </div>



    </ThemeProvider >
  );
}
export default Home; 