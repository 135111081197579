import styled from "styled-components";

export const StyledProject = styled.button`
  background-color: black;
  border: none;  
  
  .container {
    position: relative;
    border: solid 0.5px grey;
    height: 230px;
    width: 230px;
    background-color: black;
  }

  .overlay {
    position: absolute;
    height: 230px;
    width: 230px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container:hover .overlay,
  container:active .overlay {
    opacity: 0.99;
  }

  .text {
    color: white; 
    font-weight: bold;
    font-size: 1rem;
  }

  .image_container {
    height: 230px;
    width: 230px;
    display: flex;
    justify-content: center;
  }

  .image {
    max-width:230px;
    max-height:230px;
  }


  @media (min-width: ${({ theme }) => theme.mobile_max}) {
  }
`;
