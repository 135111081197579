// AboutMe.js
import React from "react";
import styled from "styled-components";

const StyledAboutMe = styled.div`
    text-align: center; 
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
    color: black; 
    max-width: 1200px; 
    margin: 23px auto;
    margin-bottom: 40px; 
    
    img {
        max-width: 200px;
        border-radius: 50%; 
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 14px;
    } 
`;

const AboutMe = ({ name, description }) => {
    return (
        <StyledAboutMe>
            <img
                src={process.env.PUBLIC_URL + "/assets/pietrobonazzi.jpeg"}
                alt={name}
            />
            <h2>{name}</h2>
            {description}
        </StyledAboutMe>
    );
};

export default AboutMe;
