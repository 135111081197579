import React from "react";
import { StyledProject } from "./Project.styled";
import { Link } from "react-router-dom";
import { analytics } from "../../firebase/firebase";
import { logEvent } from "firebase/analytics";

const Project = ({ img, name, link }) => {
  logEvent(analytics, 'select_content', { name: name });

  return (
    <StyledProject>
      <div style={{ border: "1px solid black", zIndex: 1, display: "flex", justifyContent: "center", marginBottom: "20px" }}>
        {link !== "" ?
          <div className="container" style={{ border: "0px solid black", backgroundColor: "white" }}>
            <Link to={link}>
              <div className="image_container">
                <img src={img} alt="Avatar" className="image" style={{ width: "100%" }} />
                <div className="overlay">
                  <div className="text">
                    {name}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          : <img src={img} alt="Avatar" className="image" />
        }


      </div>
    </StyledProject>
  );
};
export default Project;
