import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.white};
  transform: ${({ open }) => (open ? "translateX(0)" : "scaleY(0)")};
  height: 100vh;
  width: 30%;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 5;

  @media (max-width: ${({ theme }) => theme.tablet_max}) {
    width: 100%;
  }

  a {
    font-size: 2.3rem;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 2rem; 
    letter-spacing: 0.3rem;
    color: ${({ theme }) => theme.black};
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.tablet_max}) {
      font-size: 2.3rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.hover};
    }
  }
`;
