import React from "react";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import { ThemeProvider } from "styled-components";
import BarMenu from "../../components/Navigation/BarMenu";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Helmet } from "react-helmet";
import DatasetForm from "../../components/DatasetForm";
import Google from "./Google/Google";
import Crispr from "./Crispr/Crispr";
import Background from "../../components/Background";
import { CrisprDataset } from "./Crispr/CrisprDataset";

const BlogPost = ({
  helmet,
  name,
  context,
  description,
  image_page,
  partners_names,
  tumbnail,
  link1,
  link2,
  linkcontext,
  maincontext,
  dataset,
  demo,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <Helmet>
        <title>{helmet}</title>
      </Helmet>

      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "black",
        }}
      >
        <div
          className="cont"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "60px",
            maxWidth: "600px",
          }}
        >
          <div style={{ marginBottom: "30px" }}>
            <Link to={"/projects"}>
              <ArrowBackIosIcon sx={{ color: "white" }} />
            </Link>
          </div>

          <BarMenu
            maincontext={maincontext}
            context={context}
            linkcontext={linkcontext}
          />

          <div
            className="title"
            style={{ fontSize: 30, marginTop: 20, marginBottom: 20 }}
          >
            {name}
          </div>

          <div
            className="organization"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 30 }}
          >
            {partners_names}
          </div>

          {image_page !== 0 ? (
            <img
              src={tumbnail}
              alt={""}
              style={{
                border: "white solid 1px",
                width: "100%",
                maxWidth: "600px",
                marginBottom: "30px",
              }}
            />) : null}

          {link1[0] !== "" ? (
            <div style={{ display: "flex", marginBottom: "30px" }}>
              <div>
                <a
                  href={link1[0]}
                  style={{
                    border: "white solid 1px",
                    borderRadius: "20px",
                    width: "20px",
                    color: "white",
                    marginRight: "15px",
                    padding: "10px",
                  }}
                >
                  {link1[1]}
                </a>
              </div>
              {link2[0] !== "" ? (
                <div>
                  <a
                    href={link2[0]}
                    style={{
                      border: "white solid 1px",
                      borderRadius: "20px",
                      width: "20px",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    {link2[1]}
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}

          {description !== "" ? (
            <div
              className="subtitle"
              style={{ fontSize: 23, marginBottom: 20 }}
            >
              Description
              <div
                className="description"
                style={{
                  marginTop: 20,
                  fontSize: 14,
                  display: "block",
                  textAlign: "justify",
                  textJustify: "inter-character",
                }}
              >
                {description}
              </div>
            </div>
          ) : null}

          <script type='text/javascript' src='https://storage.ko-fi.com/cdn/widget/Widget_2.js'></script><script type='text/javascript'>kofiwidget2.init('Sponsor a Open Source Dataset', '#29abe0', 'H2H511JF8P');kofiwidget2.draw();</script>

          {dataset === "crispr" ? (
            <CrisprDataset />
          ) : dataset === "ini-30-dataset" ? (
            <DatasetForm dataset={dataset} />
          ) : null}

          {demo !== "" ?
            <div
              className="subtitle"
              style={{ fontSize: 23, marginBottom: 20 }}
            >
              Demo
            </div> : null}

          {demo === "crispr" ? (
            <Crispr />
          ) : demo === "google" ? (
            <div> Currently not available </div>
          ) : null}
        </div>
      </div>
      <Background />
    </ThemeProvider >
  );
};

export default BlogPost;
