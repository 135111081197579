import React, { useState, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Ensure this line matches the root id in your HTML

const CrisprDataset = () => {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [vocab, setVocab] = useState({});
    const [columnDefs, setColumnDefs] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [displayTranslated, setDisplayTranslated] = useState(false); // State for toggling translated data

    const csvDataUrl = process.env.PUBLIC_URL + "/assets/all_data.csv";
    const iupacDataUrl = process.env.PUBLIC_URL + "/assets/iupac_vocab.tsv";

    // Parse CSV data into an array of objects
    const parseCSV = (csv) => {
        const lines = csv.split("\n");
        const headers = lines[0].split(",");
        const data = [];
        for (let i = 1; i < lines.length; i++) {
            const values = lines[i].split(",");
            const entry = {};
            for (let j = 0; j < headers.length; j++) {
                entry[headers[j]] = values[j];
            }
            data.push(entry);
        }
        console.log("Parsed CSV Data:", data);
        return data;
    };

    // Parse TSV vocab data into an object
    const parseTSV = (tsv) => {
        const lines = tsv.split("\n");
        const vocab = {};
        for (let i = 0; i < lines.length; i++) {
            const [key, value] = lines[i].split("\t");
            vocab[key] = value;
        }
        console.log("Parsed TSV Vocab:", vocab);
        return vocab;
    };

    const translateData = (data, vocab) => {
        return data.map((row) => {
            const translatedRow = { ...row };
            ["m1", "m2", "m3", "m4"].forEach((col) => {
                if (translatedRow[col] && vocab[translatedRow[col]]) {
                    translatedRow[col] = vocab[translatedRow[col]].replace(/[\r\n]+/g, ' ').trim();
                }
            });
            console.log("Translated Row:", translatedRow);
            return translatedRow;
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [csvResponse, tsvResponse] = await Promise.all([
                    fetch(csvDataUrl),
                    fetch(iupacDataUrl)
                ]);

                if (!csvResponse.ok || !tsvResponse.ok) {
                    throw new Error("Network response was not ok");
                }

                const csv = await csvResponse.text();
                const tsv = await tsvResponse.text();

                const parsedCSV = parseCSV(csv);
                const parsedVocab = parseTSV(tsv);

                let data = parsedCSV;

                if (displayTranslated) {
                    data = translateData(parsedCSV, parsedVocab);
                }

                setTableData(data);
                console.log("Data:", data);

                // Define the desired column order
                const desiredOrder = ["Transfection Efficacy", "p1", "m1", "p2", "m2", "p3", "m3", "p4", "m4"];

                // Generate column definitions dynamically based on desired order
                const colDefs = desiredOrder.map(key => ({
                    headerName: key,
                    field: key
                }));

                setColumnDefs(colDefs);
                console.log("Column Definitions:", colDefs);

                setVocab(parsedVocab);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [csvDataUrl, iupacDataUrl, displayTranslated]);

    const handleCellClick = (event) => {
        setModalContent(event.value);
        setModalIsOpen(true);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <div className="subtitle" style={{ fontSize: 23, marginTop: 20, marginBottom: 20 }}>
                Dataset
            </div>

            <div style={{ marginBottom: '20px' }}>
                <button
                    onClick={() => setDisplayTranslated(prevState => !prevState)}
                    style={{
                        padding: '8px 12px',
                        fontSize: '12px',
                        color: 'white',
                        backgroundColor: displayTranslated ? '#007bff' : '#28a745',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    {displayTranslated ? "Show SMILES" : "Show IUPAC"}
                </button>
            </div>

            <div className="ag-theme-alpine" style={{ height: 400, width: '100%', marginBottom: 40 }}>
                <AgGridReact
                    rowData={tableData}
                    columnDefs={columnDefs}
                    defaultColDef={{ sortable: true, filter: true }}
                    onCellClicked={handleCellClick}
                />
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Cell Content"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        width: '25%',
                        marginRight: '-50%',
                        color: 'black',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
            >
                <h2>Content Description</h2>
                <div style={{ marginBottom: 20 }}>{modalContent}</div>
                <button onClick={() => setModalIsOpen(false)}>Close</button>
            </Modal>
        </div>
    );
};

export { CrisprDataset };
