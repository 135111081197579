import React from "react";
import { render } from "react-dom";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import ProjectIndex from "./pages/Projects/ProjectIndex";
import BlogPost from "./pages/Projects/BlogPost";
import { projects } from "./data/projects_list";

import ReactGA from "react-ga";
const TRACKING_ID = "G-2M0BLKFL8M";
ReactGA.initialize(TRACKING_ID);

const rows = [];

// add paths
for (let i = 0; i < projects.length; i++) {
  let path = "/projects/" + projects[i].webname;

  rows.push(
    <Route
      key={i}
      exact
      path={path}
      element={
        <BlogPost
          helmet={projects[i].helmet}
          name={projects[i].name}
          maincontext={"Projects >"}
          linkcontext={"/projects"}
          context={projects[i].context}
          partners_names={projects[i].partners_names}
          tumbnail={projects[i].image}
          link1={projects[i].link1}
          link2={projects[i].link2}
          dataset={projects[i].dataset}
          demo={projects[i].demo}
          image_page={projects[i].image_page}
          description={projects[i].description}
        />
      }
    />
  );
}
export const routing = (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/projects" element={<ProjectIndex />} />
      {/* <Route exact path="/biography" element={<Biography />} /> */}
      {rows}
    </Routes>
  </BrowserRouter>
);

const rootElement = document.getElementById("root");
render(routing, rootElement);
