import React from "react";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import { ThemeProvider } from "styled-components";

import SecondaryNews from "../../components/News/SecondaryNews/SecondaryNews";
import PrimaryNews from "../../components/News/PrimaryNews/PrimaryNews";
import { news } from "../../data/news_list";
import styled from "styled-components";
import AboutMe from "./AboutMe";

const StyledNewsSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Add spacing between news items */
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;
`;

function FirstPage() {
    const newsItems = news.map((item) => (
        <SecondaryNews
            key={item.name}
            name={item.name}
            title={item.title}
            description={item.description}
            website={item.website}
        />
    ));



    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div style={{ display: "flex", flexDirection: "column", padding: 30, justifyContent: "center", backgroundColor: "white" }}>

                <AboutMe name={"Pietro Bonazzi"} description={"I am a PhD Researcher at ETH Zürich, Switzerland, working with PD Dr. Michele Magno. Previously, I received my MSc. degree from the University of Zürich, supervised by Prof. Scaramuzza. I was a research student at Google for my Master Thesis and Project."} />

                <div style={{ fontSize: "23px", textAlign: "center", marginBottom: "30px", color: "black" }}>
                    News & Publications
                </div>
                <StyledNewsSection>{newsItems}</StyledNewsSection>
            </div>



        </ThemeProvider >
    );
}
export default FirstPage; 