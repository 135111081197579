import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const DatasetForm = ({ dataset }) => {
  const form = useRef();
  const [isSubmitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (isSubmitted) {
      console.log("Form already submitted.");
      return;
    }
    emailjs
      .sendForm("service_5plgevg", dataset, form.current, "rPV3cHrOHZX-J4pJP")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div
        className="subtitle"
        style={{ fontSize: 23, marginTop: 20, marginBottom: 20 }}
      >
        Dataset
      </div>

      {isSubmitted ? (
        <div>
          Thank we will send you the link for downloading Ini-30 ASAP. <p />{" "}
        </div>
      ) : (
        <div>
          Please, complete this form to download the dataset. <p />
          <div className="form" style={{ fontSize: 12, marginTop: 30 }}>
            <form ref={form} onSubmit={sendEmail}>
              <label>Name (Required) : </label> <p />
              <input
                type="text"
                name="user_name"
                placeholder="Your Name"
                required
              />
              <p />
              <label>Email (Required) : </label> <p />
              <input
                type="email"
                name="user_email"
                placeholder="Your Email"
                required
              />
              <p />
              <label>Message (Required) : </label> <p />
              <textarea
                name="message"
                rows="8"
                placeholder="Your Message"
                required
              />
              <p />
              <input
                type="submit"
                disabled={isSubmitted}
                value="SEND"
                style={{
                  color: "white",
                  backgroundColor: "black",
                  cursor: "pointer",
                  border: "white solid 1px",
                  width: "90px",
                  padding: "10px",
                  fontSize: "14px",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              />
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DatasetForm;
