import React from "react";
import { GlobalStyles } from "../../styles/global";
import { theme } from "../../styles/theme";
import { ThemeProvider } from "styled-components";
import { projects } from "../../data/projects_list";
import Project from "../../components/Project/Project";
import Divider from "@mui/material/Divider";
import { Helmet } from "react-helmet";
import Header from "../../components/Navigation/Header";
import Background from "../../components/Background";

function ProjectIndex() {
  const rows = [];

  for (let i = 0; i < projects.length; i++) {
    if (projects[i].type === "title") {
      rows.push(
        <h1 key={i} className="description" style={{ marginTop: 20 }}>
          {projects[i].description}
        </h1>
      );
    } else {
      rows.push(
        <div
          key={i}
          style={{
            padding: 30,
            color: "white",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Divider
            style={{
              minHeight: "270px",
              height: "10%",
              width: "10px",
              position: "absolute",
              left: "50%",
              zIndex: 0,
              backgroundColor: "white",
            }}
          />

          <Project
            name={projects[i].name}
            img={projects[i].image}
            client={projects[i].client}
            link={projects[i].webname}
          />
        </div>
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <div className="Frame">
        <Helmet>
          <title>Projects</title>
        </Helmet>
        <Header />
        <div
          className="projects"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px",
          }}
        >
          {rows}
        </div>
        <Background />
      </div>
    </ThemeProvider>
  );
}
export default ProjectIndex;
