import React, { useState, useEffect } from "react";
import { GoogleDemo } from "./GoogleDemo"

const Google = () => {

  const [data, setData] = useState({});
  const [relV, setRV] = useState({});
  const [objV, setOV] = useState({});
  const [objVc, setOVc] = useState({});

  useEffect(() => {
    fetch('/api/load_data', {
      'methods': 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => { setData(data) })
      .catch(error => console.log(error))

  }, [])


  useEffect(() => {
    fetch('/api/threedssg_vocabularies', {
      'methods': 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        setOV(data["obj"])
        setOVc(data["obj_colors"])
        setRV(data["rel"])
      })
  }, [])
  // scene graphs

  return (
    <div className="cont">

      <div className="visualization" style={{}}>
        <GoogleDemo elements={data} relV={relV} objV={objV} objVc={objVc} />
      </div>

    </div>
  );
};

export default Google;
