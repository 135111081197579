import React, { useEffect, useState } from 'react';

import InputForm from './InputForm';
import ResultsDisplay from './ResultsDisplay';
import { PredictEfficacy } from './Fetching/PredictEfficacy';


export const CrisprDemo = ({ elements }) => {
  const fieldCount = 4;
  const [formData, setFormData] = useState(() => {
    const initialState = {};
    for (let i = 1; i <= fieldCount; i++) {
      initialState[`P${i}`] = "";
      initialState[`M${i}`] = "";
    }
    return initialState;
  });


  const { handleSubmit, result } = PredictEfficacy(formData);
  const [displayTranslated, setDisplayTranslated] = useState(false);

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <button
          onClick={() => setDisplayTranslated(prevState => !prevState)}
          style={{
            padding: '8px 12px',
            fontSize: '12px',
            color: 'white',
            backgroundColor: displayTranslated ? '#007bff' : '#28a745',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          {displayTranslated ? "Search with SMILES" : "Search with IUPAC"}
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <InputForm fieldCount={fieldCount} displayTranslated={displayTranslated} setFormData={setFormData} formData={formData} />
        <button type="submit"
          style={{
            marginTop: '10px',
            padding: '8px 12px',
            fontSize: '12px',
            color: 'black',
            backgroundColor: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        > Submit
        </button>
      </form>
      <ResultsDisplay result={result} />
    </div>
  );
};
