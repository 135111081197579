import React, { Component } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { throttle } from "lodash-es";

const resizeUpdateInterval = 500;

function setCanvasDimensions(canvas, width, height) {
  const ratio = window.devicePixelRatio; // physical resolution to CSS resolution
  canvas.width = width * ratio;
  canvas.height = height * ratio;
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
}

export class Google3D extends Component {
  componentDidMount() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color("0x404040");
    const number_of_objects = Object.keys(this.props.points).length;

    for (let j = 0; j < number_of_objects; j++) {
      const geometry = new THREE.BufferGeometry();

      const number_of_triangles = Object.keys(this.props.points[j][0]).length;
      const indices = [];
      const normals = [];
      const colors = [];

      for (let i = 0; i < number_of_triangles; i++) {
        indices.push(
          this.props.points[j][0][i][0],
          this.props.points[j][0][i][1],
          this.props.points[j][0][i][2]
        );
      }
      const number_of_vertices = Object.keys(this.props.points[j][1]).length;
      const vertices = [];
      for (let i = 0; i < number_of_vertices; i++) {
        normals.push(
          this.props.points[j][3][i][0],
          this.props.points[j][3][i][1],
          this.props.points[j][3][i][2]
        );
        vertices.push(
          this.props.points[j][1][i][0],
          this.props.points[j][1][i][1],
          this.props.points[j][1][i][2]
        );
        colors.push(
          this.props.points[j][2][0] / 255,
          this.props.points[j][2][1] / 255,
          this.props.points[j][2][2] / 255
        );
      }
      geometry.setIndex(indices);
      geometry.setAttribute(
        "normal",
        new THREE.Float32BufferAttribute(normals, 3)
      );
      geometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(vertices, 3)
      );
      geometry.setAttribute(
        "color",
        new THREE.Float32BufferAttribute(colors, 3)
      );
      const material = new THREE.MeshPhongMaterial({
        side: THREE.DoubleSide,
        vertexColors: true,
      });
      let mesh = new THREE.Mesh(geometry, material);
      this.scene.add(mesh);
    }

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor("0x404040");
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize((window.innerWidth * 1) / 2 - 200, 500);
    this.mount.appendChild(this.renderer.domElement);

    //Add Camera
    const fov = 45;
    const w = (window.innerWidth * 1) / 2 - 80;
    const h = 500;
    const aspect = w / h;
    const near = 0.0005;
    const far = 20;
    this.camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
    this.camera.position.set(0, -1, 10);

    //Resize
    window.addEventListener(
      "resize",
      throttle(
        () => {
          const width = (window.innerWidth * 1) / 2 - 200;
          const height = 500;
          this.camera.aspect = width / height;
          this.camera.updateProjectionMatrix();
          this.renderer.setSize(width, height);
          setCanvasDimensions(this.renderer.domElement, width, height);
        },
        resizeUpdateInterval,
        { trailing: true }
      )
    );

    //Settings
    //Add Camera Controls
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.target.set(
      this.props.camera_target[0],
      this.props.camera_target[2],
      this.props.camera_target[1]
    );
    controls.minDistance = 0.001;
    controls.maxDistance = 10;
    controls.update();

    ///Add AMBIENT LIGHT
    let light = new THREE.DirectionalLight(0xffffff, 1.0);
    light.position.set(20, 100, 10);
    light.target.position.set(0, 0, 0);
    light.castShadow = true;
    light.shadow.bias = -0.001;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = 500.0;
    light.shadow.camera.near = 0.5;
    light.shadow.camera.far = 500.0;
    light.shadow.camera.left = 100;
    light.shadow.camera.right = -100;
    light.shadow.camera.top = 100;
    light.shadow.camera.bottom = -100;
    this.scene.add(light);

    light = new THREE.AmbientLight(0xffffff, 1.5);
    this.scene.add(light);

    //Start animation
    this.start();
  }

  //Unmount when animation has stopped
  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }

  //Function to start animation
  start = () => {
    //Rotate Models
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  //Function to stop animation
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  //Animate models here
  animate = () => {
    //ReDraw scene with camera and scene object
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };

  //Render the scene
  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  };

  render() {
    return (
      <div style={{}}>
        <div
          ref={(mount) => {
            this.mount = mount;
          }}
        />
      </div>
    );
  }
}
