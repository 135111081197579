import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
    font-family: "Inter", sans-serif;
    color: ${({ theme }) => theme.white};
  }
  
  body {
    background: ${({ theme }) => theme.background};
    font-size: 16px;
  }

  h1 {
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 1px; 
    text-align: center;
  }

  h2 {
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase; 
    text-align: center;
  }
  
  ul {
    list-style-type: none;
    line-height: 20px; 
}
  
  a {
    color: ${({ theme }) => theme.href};
    text-decoration: none;
  }

  projects {
    display: "flex";
    flex-direction: "row";
    flex-wrap: "wrap";
    background: ${({ theme }) => theme.white}
  }


`;
