import styled from "styled-components";

export const StyledPrimaryNews = styled.button`
  background-color: white;
  border: none;  
  align-self: center;  
  align-text: left;
  color: black;

  .image {
    display: block;  /* Set to 'block' by default */
  }
  .text-container {
    width: 100%; 
    width: 60%; 
    color: black;
    margin-top: 30px;
  }

  @media (max-width: ${({ theme }) => theme.tablet_max}) {
    .image {
      display: none;  /* Set to 'none' when the screen width is greater than tablet_max */
    }
    .text-container {
      width: 100%;
    }
  }
  
`;
