import React from 'react';

const ResultsDisplay = ({ result }) => {
    if (result === null) return null;

    const renderResult = (title, isHighEfficiency, confidence, confidencePercentage = false) => (
        <div style={{ textAlign: "center" }}>
            <h4>{title}</h4>
            <span className={isHighEfficiency ? "high-confidence" : "low-confidence"}>
                {isHighEfficiency ? "High efficiency" : "Low efficiency"}
                <div style={{ color: isHighEfficiency ? "green" : "red" }}>
                    {confidencePercentage ? `[${(confidence * 100).toFixed(2)}% confidence]` : `[${confidence.toFixed(2)} confidence]`}
                </div>
            </span>
        </div>
    );

    return (
        <div>
            <div style={{ marginTop: "10px", textAlign: "center" }}>
                <h3>Transfection Efficiency Results</h3>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                {renderResult("Random Forest", result.random_forest[0][0] <= result.random_forest[0][1], result.random_forest[0][1], true)}
                {renderResult("SVM", result.svm[0] > 0, Math.abs(result.svm[0]))}
                {renderResult("XGBoost", result.xgb[0][0] <= result.xgb[0][1], result.xgb[0][1], true)}
            </div>
        </div>
    );
};

export default ResultsDisplay;
