import React from "react";
import CytoscapeComponent from "react-cytoscapejs/src";
import { GlobalStyles } from "../../../styles/global";
import { theme } from "../../../styles/theme";
import { ThemeProvider } from "styled-components";
import Cytoscape from "cytoscape";
import COSEBilkent from "cytoscape-spread";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Button from "@mui/material/Button";
import Select from "react-select";
import cloneDeep from "lodash/cloneDeep";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { Google3D } from "./Google3D";
Cytoscape.use(COSEBilkent);

export class GoogleDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      vocabularies_loading: true,
      scene_graph_loading: true,
      scene_graphs: [],
      scene_graph_construction: false,

      model: "gnn",
      camera_target: [],
      mesh_loading: true,
      mesh_points: [],

      pop_window_add_edges: false,
      new_source: "",
      new_label: "",
      new_label_code: "",
      new_target: "",
      added_edges: [],

      pop_window_add_nodes: false,
      n_of_nodes: "0",
      new_node_id: "",
      new_node_label: "",
      new_node_color: "",
      added_nodes: [],
    };
    this.handleRelationship = this.handleRelationship.bind(this);
    this.handleSrc = this.handleSrc.bind(this);
    this.handleDst = this.handleDst.bind(this);
    this.handleAddNode = this.handleAddNode.bind(this);
    this.model_selection = this.model_selection.bind(this);
  }

  // generating the mesh
  getPoints() {
    let idx;
    this.setState({ mesh_loading: true });
    if (!this.state.scene_graph_construction) {
      idx = this.state.count;
    } else {
      idx = "custom";
    }
    fetch("/api/get_points", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idx: idx,
        added_edges: this.state.added_edges,
        added_nodes: this.state.added_nodes,
        model: this.state.model,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({ mesh_points: res["pcd"] });
        this.setState({ camera_target: res["camera_target"] });
        this.setState({ mesh_loading: false });
      });
  }

  // going through dataset
  nextScene() {
    this.setState({
      added_edges: [],
    });
    this.setState({
      added_nodes: [],
    });
    if (this.state.count === this.props.elements["graphs"].length - 1) {
      this.setState({ count: 0 });
    } else {
      this.setState({ count: this.state.count + 1 });
    }
  }
  previousScene() {
    this.setState({ added_edges: [] });
    this.setState({
      added_nodes: [],
    });
    if (this.state.count === 0) {
      this.setState({ count: this.props.elements["graphs"].length - 1 });
    } else {
      this.setState({ count: this.state.count - 1 });
    }
  }

  // clear added edges and nodes
  model_selection(event) {
    this.setState({ model: event.target.value });
  }

  // clear added edges and nodes
  clearModifications() {
    this.setState({ added_edges: [] });
    this.setState({ added_nodes: [] });
  }

  // adding the edges
  open_add_edges_window() {
    this.setState({ pop_window_add_edges: !this.state.pop_window_add_edges });
  }
  handleRelationship(event) {
    this.setState({ new_label: event.label });
    this.setState({ new_label_code: event.value });
  }
  handleSrc(event) {
    this.setState({ new_source: event.value });
  }
  handleDst(event) {
    this.setState({ new_target: event.value });
  }

  handleSubmit() {
    this.setState((prevState) => ({
      added_edges: [
        ...prevState.added_edges,
        {
          data: {
            source: this.state.new_source,
            label: this.state.new_label,
            target: this.state.new_target,
            label_code: this.state.new_label_code,
          },
        },
      ],
    }));
  }

  // add nodes
  open_add_nodes_window() {
    this.setState({ pop_window_add_nodes: !this.state.pop_window_add_nodes });
  }
  handleAddNode(event) {
    this.setState({ new_node_label: event.label });
    this.setState({ new_node_id: event.value });
    this.setState({ new_node_color: event.color });
  }
  handleSubmitNode() {
    this.setState((prevState) => ({
      added_nodes: [
        ...prevState.added_nodes,
        {
          data: {
            id: this.state.n_of_nodes,
            global_id: this.state.new_node_id,
            label: this.state.new_node_label,
            color: this.state.new_node_color,
          },
        },
      ],
    }));
  }

  // custom
  handleCustom() {
    this.setState({
      scene_graph_construction: !this.state.scene_graph_construction,
    });
    this.setState({ added_edges: [] });
    this.setState({ added_nodes: [] });
  }

  render() {
    let myCyRef;
    let elements = [];
    let is3DSSGLoaded;
    let options_available_nodes = [];

    let options_nodes = [];
    let options_relationships = [];

    const layout = {
      name: "grid",
      animate: true, // Whether to show the layout as it's running
      ready: undefined, // Callback on layoutready
      stop: undefined, // Callback on layoutstop
      fit: true, // Reset viewport to fit default simulationBounds
      minDist: 20, // Minimum distance between nodes
      padding: 20, // Padding
      expandingFactor: -1.0, // If the network does not satisfy the minDist
      prelayout: { name: "cose" }, // Layout options for the first phase
      maxExpandIterations: 4, // Maximum number of expanding iterations
      boundingBox: undefined, // Constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
      randomize: false, // Uses random initial node positions on true
    };

    // inference on 3dssg
    is3DSSGLoaded = Object.keys(this.props.elements).length;
    if (is3DSSGLoaded === 1 && !this.state.scene_graph_construction) {
      // get the scene graph
      const temp = cloneDeep(this.props.elements["graphs"][this.state.count]);

      // add addded nodes and edges
      for (var e = 0; e < this.state.added_edges.length; e++) {
        temp.push(this.state.added_edges[e]);
      }
      for (var n = 0; n < this.state.added_nodes.length; n++) {
        temp.push(this.state.added_nodes[n]);
      }

      // add the available nodes for edges manipulation
      for (var p = 0; p < Object.keys(temp).length; p++) {
        if ("color" in temp[p]["data"]) {
          options_available_nodes.push({
            value: temp[p]["data"]["id"],
            label: temp[p]["data"]["label"],
            color: temp[p]["data"]["color"],
          });
        }
      }

      // set this as cytoscape diagram
      elements = temp;
      this.state.n_of_nodes = Object.keys(temp).length.toString();
      this.state.scene_graph_loading = false;
    }
    // scene construction
    else if (this.state.scene_graph_construction) {
      // get the scene graph
      const temp = cloneDeep([]);

      // add addded nodes and edges
      for (var j = 0; j < this.state.added_edges.length; j++) {
        temp.push(this.state.added_edges[j]);
      }
      for (var k = 0; k < this.state.added_nodes.length; k++) {
        temp.push(this.state.added_nodes[k]);
        console.log(this.state.added_nodes[k]);
        options_available_nodes.push({
          value: this.state.added_nodes[k]["data"]["id"],
          label: this.state.added_nodes[k]["data"]["label"],
          color: this.state.added_nodes[k]["data"]["color"],
        });
      }

      // set this as cytoscape diagram
      elements = temp;
      this.state.n_of_nodes = this.state.added_nodes.length.toString();
      this.state.scene_graph_loading = false;
    }

    // scene manipulation
    if (
      Object.keys(this.props.relV).length !== 0 &&
      Object.keys(this.props.objV).length !== 0 &&
      Object.keys(this.props.objVc).length !== 0
    ) {
      const list_rel_ids = Object.keys(this.props.relV);
      const list_rel_labels = Object.values(this.props.relV);
      for (var i = 0; i < list_rel_ids.length; i++) {
        options_relationships.push({
          value: list_rel_ids[i],
          label: list_rel_labels[i],
        });
      }

      const list_obj_ids = Object.keys(this.props.objVc);
      console.log(this.props.objV);
      const list_obj_colors = Object.values(this.props.objVc);
      const list_obj_labels = Object.values(this.props.objV);
      for (var l = 0; l < list_obj_ids.length; l++) {
        options_nodes.push({
          value: list_obj_ids[l],
          label: list_obj_labels[l],
          color: list_obj_colors[l],
        });
      }

      this.state.vocabularies_loading = false;
    }

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />

        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                fontFamily: "Inter",
                fontSize: 14,
                fontWeight: 600,
                color: "white",
                marginRight: 10,
              }}
            >
              Choose the model :
            </div>

            <FormControl>
              <RadioGroup
                row
                sx={{ color: "white" }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={this.state.model}
                onChange={this.model_selection}
              >
                <FormControlLabel
                  value="gnn"
                  control={<Radio sx={{ color: "white" }} />}
                  label="GNN"
                />
                <FormControlLabel
                  value="gnna"
                  control={<Radio sx={{ color: "white" }} />}
                  label="GNNA"
                />
                <FormControlLabel
                  value="gph"
                  control={<Radio sx={{ color: "white" }} />}
                  label="GPH"
                />
                <FormControlLabel
                  value="gtn"
                  control={<Radio sx={{ color: "white" }} />}
                  label="GTN"
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div
            className="visualization"
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "500px",
              paddingTop: 20,
              margin: 40,
              justifyContent: "center",
            }}
          >
            <div className="input box" style={{ width: "45%" }}>
              <div>
                {(this.state.scene_graph_construction &&
                  !this.state.vocabularies_loading) ||
                (!this.state.scene_graph_loading &&
                  !this.state.vocabularies_loading) ? (
                  <div
                    className="diagram"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontWeight: 600,
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      Scene Graph
                    </div>

                    <CytoscapeComponent
                      zoomingEnabled={true}
                      maxZoom={3}
                      minZoom={0.5}
                      autounselectify={false}
                      boxSelectionEnabled={true}
                      elements={elements}
                      layout={layout}
                      cy={(cy) => {
                        myCyRef = cy;
                        cy.on("select", () => {
                          console.log("something was selected here");
                        });
                      }}
                      stylesheet={[
                        {
                          selector: "node",
                          style: {
                            "background-color": "data(color)",
                            label: "data(label)",
                            "text-valign": "left",
                            "text-halign": "left",
                            color: "white",
                            "font-size": 10,

                            "text-background-opacity": 1,
                            "text-background-color": "black",
                            "text-background-shape": "roundrectangle",
                            "text-border-color": "black",
                            "text-border-width": 0.3,
                            "text-border-opacity": 1,
                          },
                        },
                        {
                          selector: "edge",
                          style: {
                            width: 1,
                            "font-size": 10,
                            "line-color": "white",
                            "target-arrow-color": "white",
                            "target-arrow-shape": "triangle",
                            "curve-style": "bezier",
                            color: "white",
                            label: "data(label)",

                            "text-background-opacity": 1,
                            "text-background-color": "black",
                            "text-background-shape": "roundrectangle",
                            "text-border-color": "black",
                            "text-border-width": 0.3,
                            "text-border-opacity": 1,
                          },
                        },
                      ]}
                      style={{
                        width: "100%",
                        height: "500px",
                      }}
                    />

                    <div
                      className="sidebar"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        border: "solid 1px white",
                      }}
                    >
                      {!this.state.scene_graph_construction ? (
                        <IconButton
                          sx={{ color: "white" }}
                          aria-label="previous"
                          onClick={() => this.handleCustom()}
                        >
                          <FiberNewIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{ color: "white" }}
                          aria-label="previous"
                          onClick={() => this.handleCustom()}
                        >
                          <DataObjectIcon />
                        </IconButton>
                      )}

                      {!this.state.scene_graph_construction ? (
                        <div
                          className="sidebar"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            sx={{ color: "white" }}
                            aria-label="previous"
                            onClick={() => this.previousScene()}
                          >
                            <SkipPreviousIcon />
                          </IconButton>

                          <div>
                            {" "}
                            {this.state.count}/
                            {this.props.elements["graphs"].length}
                          </div>

                          <IconButton
                            sx={{ color: "white" }}
                            aria-label="next"
                            onClick={() => this.nextScene()}
                          >
                            <SkipNextIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <div />
                      )}

                      <IconButton
                        sx={{ color: "white" }}
                        aria-label="previous"
                        onClick={() => this.clearModifications()}
                      >
                        <RestartAltIcon />
                      </IconButton>

                      <Button
                        variant="outlined"
                        sx={{ color: "white" }}
                        onClick={() => this.open_add_edges_window()}
                      >
                        {" "}
                        Add Edges{" "}
                      </Button>
                      <Dialog
                        open={this.state.pop_window_add_edges}
                        onClose={this.open_add_edges_window}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "50%",
                              height: "50%",
                              maxHeight: "300px", // Set your width here
                              maxWidth: "500px", // Set your width here
                              display: "flex",
                              justifyContent: "center",
                            },
                          },
                        }}
                      >
                        <DialogTitle id="responsive-dialog-title">
                          <IconButton
                            aria-label="close"
                            onClick={() => this.open_add_edges_window()}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Add an edge between two nodes
                          </DialogContentText>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Select
                              autoFocus
                              label="edgeLabel"
                              value={this.state.new_source.value}
                              onChange={this.handleSrc}
                              options={options_available_nodes}
                            />
                            <Select
                              autoFocus
                              label="edgeLabel"
                              value={this.state.new_label.value}
                              onChange={this.handleRelationship}
                              options={options_relationships}
                            />
                            <Select
                              autoFocus
                              label="edgeLabel"
                              value={this.state.new_target.value}
                              onChange={this.handleDst}
                              options={options_available_nodes}
                            />
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <div>
                            <Button
                              onClick={() => this.handleSubmit()}
                              autoFocus
                            >
                              Confirm
                            </Button>
                          </div>
                        </DialogActions>
                      </Dialog>

                      <Button
                        variant="outlined"
                        sx={{ color: "white" }}
                        onClick={() => this.open_add_nodes_window()}
                      >
                        {" "}
                        Add Nodes{" "}
                      </Button>
                      <Dialog
                        open={this.state.pop_window_add_nodes}
                        onClose={this.open_add_nodes_window}
                        aria-labelledby="responsive-dialog-title"
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "50%",
                              height: "50%",
                              maxHeight: "300px", // Set your width here
                              maxWidth: "500px", // Set your width here
                              display: "flex",
                              justifyContent: "center",
                            },
                          },
                        }}
                      >
                        <DialogTitle id="responsive-dialog-title">
                          <IconButton
                            aria-label="close"
                            onClick={() => this.open_add_nodes_window()}
                            sx={{
                              position: "absolute",
                              right: 8,
                              top: 8,
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText>Add a node</DialogContentText>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Select
                              autoFocus
                              label="edgeLabel"
                              value={this.state.new_label.value}
                              onChange={this.handleAddNode}
                              options={options_nodes}
                            />
                          </div>
                        </DialogContent>
                        <DialogActions>
                          <div>
                            <Button
                              onClick={() => this.handleSubmitNode()}
                              autoFocus
                            >
                              Confirm
                            </Button>
                          </div>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontFamily: "Inter",
                      fontSize: 14,
                      fontWeight: 600,
                      color: "white",
                    }}
                  >
                    {" "}
                    Fetching 3DSSG subset graphs ...{" "}
                  </div>
                )}
              </div>
            </div>

            <Button
              sx={{ color: "white" }}
              aria-label="generatePointCloud"
              style={{ height: "500px", width: "5%" }}
              onClick={() => this.getPoints()}
            >
              <SendIcon style={{ height: "500px" }} />
            </Button>

            <div className="diagram" style={{ width: "40%", height: "500px" }}>
              <div
                style={{
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "white",
                  textAlign: "center",
                }}
              >
                3D Scene
              </div>

              {!this.state.mesh_loading ? (
                <Google3D
                  points={this.state.mesh_points}
                  camera_target={this.state.camera_target}
                />
              ) : (
                <div
                  style={{
                    border: "solid 1px white",
                    color: "white",
                    display: "flex",
                    height: "500px",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
