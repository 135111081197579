import React, { useEffect, useState } from 'react';

const iupacDataUrl = process.env.PUBLIC_URL + "/assets/iupac_vocab.tsv";

const InputForm = ({ fieldCount, formData, setFormData, displayTranslated }) => {

    const [translations, setTranslations] = useState({});
    const [searchResults, setSearchResults] = useState({});

    useEffect(() => {
        const fetchTranslations = async () => {
            try {
                const response = await fetch(iupacDataUrl);
                const text = await response.text();
                const data = parseTSV(text);
                setTranslations(data);
            } catch (error) {
                console.error("Error fetching or parsing the TSV file:", error);
            }
        };

        fetchTranslations();
    }, []);

    const parseTSV = (tsv) => {
        const lines = tsv.trim().split("\n");
        const result = {};
        lines.slice(1).forEach(line => {  // Skip the first line
            const [key, value] = line.split("\t");
            result[key] = value;
        });
        return result;
    };

    const translate = (key) => {
        return translations[key] || key;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

    };

    const handleSearchChange = (event) => {
        const { name, value } = event.target;
        const lowercasedValue = value.toLowerCase();
        const filteredResults = Object.keys(translations).filter(key =>
            key.toLowerCase().includes(lowercasedValue) ||
            translations[key].toLowerCase().includes(lowercasedValue)
        );
        setSearchResults(prevState => ({
            ...prevState,
            [name]: filteredResults
        }));

        handleInputChange(event);
    };


    const handleResultClick = (name, value) => {
        handleInputChange({ target: { name, value } });
        setSearchResults(prevState => ({
            ...prevState,
            [name]: []
        }));
    };

    const generateFormFields = () => {
        const fields = [];
        for (let i = 1; i <= fieldCount; i++) {
            fields.push(
                <tr key={i} style={{ marginBottom: '10px', position: 'relative' }}>
                    <td style={{ paddingRight: '10px', width: '30%' }}>
                        <input
                            type="number"
                            name={`P${i}`}
                            value={formData[`P${i}`]}
                            onChange={handleInputChange}
                            placeholder=''
                            style={{
                                padding: '10px',
                                width: '100%',
                            }}
                        />
                    </td>
                    <td style={{ position: 'relative', width: '70%' }}>
                        <input
                            type="text"
                            name={`M${i}`}
                            value={formData[`M${i}`]}
                            onChange={handleSearchChange}
                            placeholder=''
                            style={{
                                padding: '10px',
                                width: '100%',
                            }}
                        />
                        {searchResults[`M${i}`] && searchResults[`M${i}`].length > 0 && (
                            <ul style={{
                                maxHeight: '100px',
                                overflowY: 'scroll',
                                padding: '10px',
                                margin: '0',
                                listStyleType: 'none',
                                border: '1px solid #ccc',
                                position: 'absolute',
                                width: '100%',
                                backgroundColor: 'white',
                                color: 'black',
                                zIndex: 1,
                                top: '100%',
                            }}>
                                {searchResults[`M${i}`].map((result, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleResultClick(`M${i}`, result)}
                                        style={{ padding: '10px', cursor: 'pointer' }}
                                    >
                                        {displayTranslated ? translate(result) : result}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </td>
                </tr>
            );
        }
        return fields;
    };

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
            </div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <td style={{ textAlign: 'left', paddingBottom: '10px', width: '30%' }}>Parts</td>
                        <td style={{ textAlign: 'left', paddingBottom: '10px', width: '70%' }}>Molecules</td>
                    </tr>
                </thead>
                <tbody>
                    {generateFormFields()}
                </tbody>
            </table>
        </div>
    );
};

export default InputForm;
