export const projects = [
  {
    type: "title",
    image_page: 0,
    description: "Gene Editing",
  },
  {
    id: 0,
    type: "project",
    demo: "crispr",
    dataset: "crispr",
    webname: "lnp",
    helmet: "Projects | Drug Discovery",
    name: "Lipid Nanoparticle Design for mRNA Delivery",
    image: process.env.PUBLIC_URL + "/projects/lipidnanoparticle.png",
    partners_names: "",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "AI/ML > Drug Discovery",
    link1: ["", ""],
    link2: ["", ""],
    image_page: 0,
    description: "Part of a an effort to accellerate drug delivery design for targeted medication.",
  },

  {
    type: "title",
    image_page: 0,
    description: "Computer Vision",
  },
  {
    id: 1,
    type: "project",
    demo: "",
    dataset: "",
    webname: "tinyissimov8",
    helmet: "Projects | Smart Glasses",
    name:
      "Ultra-Efficient On-Device Object Detection on AI-Integrated Smart Glasses with TinyissimoYOLO",
    image: process.env.PUBLIC_URL + "/projects/tinyissimov8.png",
    partners_names: "ETH Zurich | IEEE IOTDI 2024",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "AI/ML > Object Detection",
    link1: ["https://arxiv.org/pdf/2311.01057.pdf", "PDF"],
    link2: ["", ""],
    image_page: 0,
    description:
      "Smart glasses are rapidly gaining advanced functionality thanks to cutting-edge computing technologies,\
   accelerated hardware architectures, and tiny Artificial Intelligence (AI)\
  algorithms. Integrating AI into smart glasses featuring a smallform factor and limited\
   battery capacity is still challenging when targeting full-day\
   usage for a satisfactory user experience. This paper illustrates the design and \
   implementation of tiny machine learning algorithms exploiting novel low-power processors to\
  enable prolonged continuous operation in smart glasses. Weexplore the energy- \
  and latency-efficient of smart glasses in the case of real-time object detection.\
   To this goal, we designed a smart glasses prototype as a research platform \
   featuring two microcontrollers, including a novel milliwatt-power RISC-V parallel\
    processor with a hardware accelerator for visual AI, and a Bluetooth low-power module for communication. The\
  smart glasses integrate power cycling mechanisms, including\
  image and audio sensing interfaces. Furthermore, we developed\
  a family of novel tiny deep-learning models based on YOLO\
  with sub-million parameters customized for microcontroller-\
  based inference dubbed TinyissimoYOLO v1.3, v5, and v8,\
  aiming at benchmarking object detection with smart glasses for\
  energy and latency. Evaluations on the prototype of the smart\
  glasses demonstrate TinyissimoYOLO's 17ms inference latency\
  and 1.59mJ energy consumption per inference while ensuring\
  acceptable detection accuracy. Further evaluation reveals an\
  end-to-end latency from image capturing to the algorithm's prediction of 56ms or equivalently 18 frames per seconds (FPS),\
  with a total power consumption of 62.9mW, equivalent to a 9.3 hours of continuous run time on a 154mAh battery.\
   These results outperform MCUNet (TinyNAS+TinyEngine), which runs a simpler task (image classification) at just 7.3 FPS per second.",
  },
  {
    id: 2,
    type: "project",
    demo: "",
    webname: "tinytracker",
    dataset: "",
    helmet: "Projects | TinyTracker",
    name:
      "TinyTracker: Ultra-Fast and Ultra-Low-Power Edge Vision In-Sensor for Gaze Estimation",
    image: process.env.PUBLIC_URL + "/projects/tinytracker.png",
    partners_names: "ETH Zurich | IEEE Sensors 2023",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "AI/ML > Gaze Estimation",
    link1: ["https://arxiv.org/pdf/2307.07813.pdf", "Paper"],
    link2: ["https://github.com/pbonazzi/tinytracker", "Code"],
    image_page: 0,
    description:
      "Intelligent edge vision tasks encounter the critical\
    challenge of ensuring power and latency effciency due to\
    the typically heavy computational load they impose on edge\
    platforms. This work leverages one of the first ”AI in sensor”\
    vision platforms, IMX500 by Sony, to achieve ultra-fast and ultra-\
    low-power end-to-end edge vision applications. We evaluate the\
    IMX500 and compare it to other edge platforms, such as the\
    Google Coral Dev Micro and Sony Spresense, by exploring gaze\
    estimation as a case study. We propose TinyTracker, a highly\
    efcient, fully quantized model for 2D gaze estimation designed to\
    maximize the performance of the edge vision systems considered\
    in this study. TinyTracker achieves a 41x size reduction (∼\
    600Kb) compared to iTrackee without signicant loss in gaze\
    estimation accuracy (maximum of 0.16 cm when fully quantized).\
    TinyTracker’s deployment on the Sony IMX500 vision sensor\
    results in end-to-end latency of around 19ms. The camera takes\
    around 17.9ms to read, process and transmit the pixels to the\
    accelerator. The inference time of the network is 0.86ms with an\
    additional 0.24 ms for retrieving the results from the sensor. The\
    overall energy consumption of the end-to-end system is 4.9 mJ,\
    including 0.06 mJ for inference. The end-to-end study shows that\
    IMX500 is 1.7x faster than Coral Micro (19ms vs 34.4ms) and\
    7x more power efcient (4.9mJ VS 34.2mJ).",
  },

  // {
  //   id: 2,
  //   helmet: "Projects | 3D Printed Car",
  //   name: "3D printed ground vehicle",
  //   image: process.env.PUBLIC_URL + "/projects/picovolts.jpeg",
  //   link: "/projects/2",
  //   partners_names: "picovolts.com",
  //   partners: [process.env.PUBLIC_URL + "/companies/google.png", process.env.PUBLIC_URL + "/companies/uzh.png"],
  //   context: "Robotics > Self-Driving",
  //   link1: ["https://picovolts.com", "Commercial"],
  //   link2: ["https://www.open-ats.eu/", "OpenSource"],
  //   description: "Picovolts is a fully 3D-printed ground vehicle designed for reseearch, transportation, and observation.\
  //   It is fully controlled over mobile internet and equipped with onboard cameras, and GPU.",
  // },

  {
    id: 3,
    type: "project",
    demo: "",
    webname: "splatting",
    dataset: "",
    helmet: "Projects | Point Cloud Denoising",
    name: "Few-shot point cloud reconstruction and denoising via learned Guassian splats renderings and fine-tuned diffusion features",
    image: process.env.PUBLIC_URL + "/projects/pointcloud.png",
    partners_names: "Google Inc. | Robotics & Perception Group",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "AI/ML > Computer Graphics",
    link1: ["https://arxiv.org/pdf/2404.01112v3", "PDF"],
    link2: ["", ""],
    image_page: 0,
    description:
      "Existing deep learning methods for the reconstruction and denoising of point clouds\
     rely on small datasets of 3D shapes. We circumvent the problem by leveraging deep learning \
     methods trained on billions of images. We propose a method to reconstruct point clouds from \
     few images and to denoise point clouds from their rendering by exploiting prior knowledge \
     distilled from image-based deep learning models. To improve reconstruction in constraint \
     settings, we regularize the training of a differentiable renderer by introducing semantic \
     consistency supervision. In addition,  we propose a pipeline to finetune Stable Diffusion \
     to denoise  renderings of noisy point clouds and we demonstrate how these learned filters can be \
     used to remove point cloud noise coming without 3D supervision.  We compare our method with \
     DSS and PointRadiance and achieved higher quality 3D \
     reconstruction on the Sketchfab Testset  and SCUT Dataset.",
  },

  {
    id: 4,
    type: "project",
    demo: "google",
    dataset: "",
    webname: "scenegraph",
    helmet: "Projects | Scene Understanding",
    name: "3D scene generation from scene graphs and self-attention.",
    image: process.env.PUBLIC_URL + "/projects/google.png",
    partners_names: "Google Inc. | Robotics & Perception Group",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "AI/ML > Computer Graphics",
    link1: ["https://arxiv.org/pdf/2404.01887v2", "PDF"],
    link2: ["", ""],
    image_page: 0,
    description:
      "Synthesizing realistic and diverse indoor 3D scene layouts in a controllable fashion opens up applications in simulated navigation and virtual reality. As concise and robust representations of a scene, scene graphs have proven to be well-suited as the semantic control on the generated layout. We present a variant of the conditional variational autoencoder (cVAE) model to synthesize 3D scenes from scene graphs and floor plans. We exploit the properties of self-attention layers to capture high-level relationships between objects in a scene, and use these as the building blocks of our model. Our model, leverages graph transformers to estimate the size, dimension and orientation of the objects in a room while satisfying relationships in the given scene graph. Our experiments shows self-attention layers leads to sparser (7.9x compared to Graphto3D) and more diverse scenes (16%).",
  },
  {
    id: 0,
    type: "project",
    demo: "",
    dataset: "ini-30-dataset",
    webname: "retina",
    helmet: "Projects | Neuromorphic Eye Tracking",
    name: "Retina : Low-Power Eye Tracking with Event Camera and Spiking Hardware",
    image: process.env.PUBLIC_URL + "/projects/retina.png",
    partners_names: "ETH Zurich | IniVation| SynSense",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "AI/ML > Spiking Neuron Network",
    link1: ["https://arxiv.org/abs/2312.00425", "Paper"],
    link2: ["https://github.com/pbonazzi/retina", "Code"],
    image_page: 0,
    description:
      "This paper introduces a neuromorphic methodology for eye tracking, \
    harnessing pure event data captured by a Dynamic Vision Sensor (DVS) camera. \
    The framework integrates a directly trained Spiking Neuron Network (SNN) regression model \
    and leverages a state-of-the-art low power edge neuromorphic processor - Speck, collectively \
    aiming to advance the precision and efficiency of eye-tracking systems. First, we introduce a \
    representative event-based eye-tracking dataset, Ini- 30, which was collected with two glass-mounted\
     DVS cameras from thirty volunteers. Then,a SNN model, based on Integrate And Fire (IAF) neurons, named Retina,\
      is described , featuring only 64k parameters (6.63x fewer than the latest) and achieving pupil tracking error of \
      only 3.24 pixels in a 64x64 DVS input. The continous regression output is obtained by means of convolution using a\
       non-spiking temporal 1D filter slided across the output spiking layer. Finally, we evaluate Retina on the neuromorphic\
        processor, showing an end-to-end power between 2.89-4.8 mW and a latency of 5.57-8.01 mS dependent on the time window.\
         We also benchmark our model against the latest event-based eye-tracking method, 3ET, which was built upon event frames.\
          Results show that Retina achieves superior precision with 1.24px less pupil centroid error and reduced computational complexity\
           with 35 times fewer MAC operations. We hope this work will open avenues for further investigation of close-loop neuromorphic\
            solutions and true event-based training pursuing edge performance.",
  },

  {
    type: "title",
    image_page: 0,
    description: "Space",
  },
  {
    id: 5,
    type: "project",
    demo: "",
    dataset: "",
    webname: "cubesat",
    helmet: "Projects | Cubesat ",
    name: "The on board computer of a satellite.",
    image: process.env.PUBLIC_URL + "/projects/cubesat.png",
    partners_names: "ARIS | Center for Project-Based Learning",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "Robotics > CubeSat ",
    link1: ["https://aris-space.ch/sage-2022/", "Project"],
    link2: ["", ""],
    image_page: 0,
    description:
      "We made the first version of the on board computer of a 3U cubic satellite, which will be launched at the end of 2025. We aimed to create artificial gravity inside the satallite to study the aging of cells in different gravity conditions.",
  },

  {
    type: "title",
    image_page: 0,
    description: "Robotics",
  },

  {
    id: 6,
    type: "project",
    demo: "",
    dataset: "",
    webname: "hackahealth",
    helmet: "Projects | HackaHealth",
    name: "Wheelchair rear view solution.",
    image: process.env.PUBLIC_URL + "/projects/hanspeter.png",
    partners_names: "Hackathons | HackaHealth",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "Robotics > HackaHealth ",
    link1: ["", ""],
    link2: ["", ""],
    image_page: 0,
    description:
      "Real-time video streaming mounted on wheelchair using custom 3D  printed parts",
  },
  {
    id: 7,
    type: "project",
    demo: "",
    dataset: "",
    webname: "epuck",
    helmet: "Projects | Navigation",
    name: "Maze solver and Robot PID Controller.",
    image: process.env.PUBLIC_URL + "/projects/epuck2.png",
    partners_names: "UNIFR",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "Robotics > Control",
    link1: ["https://www.youtube.com/watch?v=uv080kCx4_8", "Video"],
    link2: ["", ""],
    image_page: 0,
    description:
      "In my last semester of Bachelor in Management, I have implemented a PID controller for a small Braitenberg's vehicle called EPUCK2S.  ",
  },
  {
    type: "title",
    image_page: 0,
    description: "Software",
  },
  {
    id: 4,
    type: "project",
    demo: "",
    dataset: "",
    webname: "jackals",
    helmet: "Projects | Typed Pandas DataFrames",
    name: "pip install jackals",
    image: process.env.PUBLIC_URL + "/projects/jackals.png",
    partners_names: "Independent",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "Software > Python Packages",
    link1: ["https://github.com/pbonazzi/jackals", "Code"],
    link2: ["", ""],
    image_page: 0,
    description: "",
  },
  {
    id: 4,
    type: "project",
    demo: "",
    dataset: "",
    webname: "studiolegalebonazzi",
    helmet: "Projects | Legal Firm Website",
    name: "studiolegalebonazzi.it",
    image: process.env.PUBLIC_URL + "/projects/studiolegalebonazzi.png",
    partners_names: "Independent",
    partners: [
      process.env.PUBLIC_URL + "/companies/google.png",
      process.env.PUBLIC_URL + "/companies/uzh.png",
    ],
    context: "Software > Website",
    link1: ["https://studiolegalebonazzi.it", "Website"],
    link2: ["", ""],
    image_page: 0,
    description: "",
  },
];
