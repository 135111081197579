import React from "react";
import { Link } from "react-router-dom";

const BarMenu = ({ context, linkcontext, maincontext }) => {
  return (
    <div className="home-bar" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      <Link to={"/"}>
        <div style={{ fontSize: 18, fontWeight: 400, color: "grey", marginRight: 5, marginTop: 5 }}>{"Pietro Bonazzi >"}</div>
      </Link>
      <Link to={linkcontext}>
        <div style={{ fontSize: 18, fontWeight: 400, color: "grey", marginRight: 5, marginTop: 5 }}>{maincontext}</div>
      </Link>
      <Link to={linkcontext}>
        <div style={{ fontSize: 18, fontWeight: 400, color: "white", marginTop: 5 }}>{context}</div>
      </Link>
    </div>
  );
};
export default BarMenu;
