import React from "react";
import { StyledSecondaryNews } from "./SecondaryNews.styled";

const SecondaryNews = ({ name, title, description, website }) => {
  return (
    <StyledSecondaryNews>
      <a href={website}>
        <img
          src={process.env.PUBLIC_URL + `/news/${name}.png`}
          alt={title}
        />
        <h3>{title}</h3>
        <p>{description}</p>
      </a>
    </StyledSecondaryNews>
  );
};

export default SecondaryNews;
