import { useState } from 'react';
import { analytics } from '../../../../firebase/firebase';
import { logEvent } from "firebase/analytics";

export const PredictEfficacy = (formData) => {

    const [result, setResult] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setResult(null);
        try {
            const response = await fetch("https://pietrobonazzi-demos.com/api/models/daisy/rf", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            setResult(data);
            logEvent(analytics, 'goal_completion', { name: "molecule_predicted" });
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return { handleSubmit, result };
};
