import styled from "styled-components";

export const StyledSecondaryNews = styled.div`
/* Add any additional styling specific to your component */
/* For example: */
border: 1px solid #e0e0e0;
padding: 16px;
width: 100%;
background-color: white;
border-radius: 8px; 
transition: background-color 0.2s ease;
margin-bottom: 10px;

&:hover {
    background-color: #f9f9f9;
}

a {
  color: black
}

img {
    width: 150px;
    height: auto;
    margin-bottom: 12px;
}

h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}

p {
    font-size: 1rem;
    color: #777;
    margin-bottom: 0;
}
`;
