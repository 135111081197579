import React from "react";
import { bool, func } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import { Link } from "react-router-dom";

const Menu = ({ open, setOpen, ...props }) => {
  const isHidden = !!open;
  //let cv = process.env.PUBLIC_URL + "/cv.pdf"

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {/* <Link to="/biography" onClick={() => setOpen(!open)}>  Biography </Link> */}
      <Link to="/" onClick={() => setOpen(!open)}>
        {" "}
        NEWS{" "}
      </Link>
      <Link to="/projects" onClick={() => setOpen(!open)}>
        {" "}
        PROJECTS{" "}
      </Link>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

export default Menu;
