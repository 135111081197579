import React, { useState, useEffect } from "react";
import { CrisprDemo } from "./CrisprDemo";

const Crispr = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://pietrobonazzi-demos.com/api/data/daisy/molecules", {
      methods: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="cont">
      <div className="visualization" style={{}}>
        {data !== null ? <CrisprDemo elements={data} /> : <div> Loading the Demo.. </div>}
      </div>
    </div>
  );
};

export default Crispr;
